export const nl_NL = {
  "error_messages": {
    "xss_error_message": "Deze tekst bevat niet-ondersteunde HTML-tags.",
    "configuration_error_title": "Configuratiefout",
    "configuration_error_message": "De boekingsdienst is niet ingeschakeld.",
    "invalid_partner_title": "Ongeldig partneradres",
    "invalid_partner_message": "Helaas konden we geen partner vinden op het door u ingevoerde adres. Probeer a.u.b. een ander adres.",
    "subscription_missing_title": "Abonnement ontbreekt",
    "subscription_missing_message": "Helaas kon er geen actief abonnement worden gevonden."
  },
  "general": {
    "mon": "Ma",
    "tue": "Di",
    "wed": "Wo",
    "thu": "Do",
    "fri": "Vr",
    "sat": "Za",
    "sun": "Zo",
    "monday": "Maandag",
    "tuesday": "Dinsdag",
    "wednesday": "Woensdag",
    "thursday": "Donderdag",
    "friday": "Vrijdag",
    "saturday": "Zaterdag",
    "sunday": "Zondag",
    "close": "Sluit",
    "load_more": "Meer laden"
  },
  "navigation_title": {
    "services": "Afspraken",
    "events": "Evenementen"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Aanbieder",
      "employees": "Werknemers",
      "resource": "Hulpbron",
      "description": "Omschrijving",
      "email": "E-mail",
      "website": "Website",
      "address": "Adres",
      "mobile": "Telefoon"
    },
    "menu": {
      "book_appointment": "Boek afspraak",
      "book_event": "Boek evenement",
      "employees": "Medewerkers",
      "branches": "Vestigingen",
      "about_us": "Over ons",
      "reviews": "Beoordelingen"
    },
    "contact_information": {
      "contact_information": "Contact informatie",
      "opening_hours": "Openingstijden",
      "closed": "Gesloten"
    },
    "hide_menu_tooltip": "Verberg menu in boekingspagina",
    "show_menu_tooltip": "Toon menu in boekingspagina",
    "edit": "Bewerk",
    "edit_bussiness_hours": "Bedrijfsuren bewerken"
  },
  "event": {
    "bookable_events": "Onze boekbare evenementen/groepsafspraken",
    "list": "Lijst",
    "grid": "Rooster",
    "store_filter": "Opslagfilter",
    "category_filter": "Categoriefilter",
    "all": "Alle",
    "book": "Boek",
    "no_place_defined": "Geen locatie bepaald",
    "free": "Gratis",
    "hidden": "Verborgen",
    "no_events": "Deze aanbieder heeft nog geen evenementen opgegeven.",
    "hidden_events_message": "De gebeurtenissen van deze partner zijn verborgen. Gebruik de link naar het evenement dat u van de partner hebt ontvangen om u te registreren.",
    "filter_result_null": "Er zijn geen resultaten die aan je criteria voldoen.",
    "no_regular_count": "Geen vrije plaatsen",
    "regular_count_singular": "1 vrije plaats",
    "regular_count_plural": "{{ number }} vrije plaatsen",
    "fully_booked": "Volgeboekt"
  },
  "event_details": {
    "place": "Plaats van uitvoering",
    "price": "Prijs",
    "meeting_type_helper_text": "Calenso genereert een online vergadering zodra de klant zich registreert",
    "taxes": "(incl. {{percentage}}% BTW)",
    "free": "Gratis",
    "hidden": "Verborgen",
    "view_on_map": "Bekijk op kaart",
    "error_while_loading_map": "Kaart kon niet worden geladen. Probeer het opnieuw!"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Het lijkt erop dat de verstrekte conferentielink ongeldig is. Controleer de link en probeer het opnieuw.",
      "expired_link": "Het spijt ons, maar deze conferentielink is verlopen. Neem contact op met de organisator voor verdere assistentie.",
      "not_belonging": "Oeps, het lijkt erop dat deze conferentie aan een andere partner is gekoppeld.",
      "booked": "Deze boekingslink is al gebruikt, dus je wordt rechtstreeks doorgestuurd naar de afspraakbevestigingspagina."
    },
    "attendees": "Deelnemers",
    "more_attendees": "+{{ count }} Meer",
    "less_attendees": "Minder deelnemers weergeven",
    "optional": "Optioneel",
    "required": "Vereist"
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - Alle rechten voorbehouden."
  },
  "page_not_found_component": {
    "subtitle_1": "Oeps, het lijkt erop dat je verdwaald bent...",
    "subtitle_2": "Neem deze kans om even te ontspannen.",
    "text": "Ontspan gedurende de volgende drie minuten en doe de streching-oefening in de video hieronder. Na de korte sessie zal je lichaam vrij zijn van negatieve gevoelens en voel je je weer als een pasgeborene.",
    "play_video": "Speel oefening video"
  }
};
