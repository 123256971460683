export const de_CH = {
  "error_messages": {
    "xss_error_message": "Dieser Text beinhaltet nicht unterstützte HTML-Tags.",
    "configuration_error_title": "Konfigurationsfehler",
    "configuration_error_message": "Der Buchungsdienst ist nicht aktiviert.",
    "invalid_partner_title": "Ungültige Partneradresse",
    "invalid_partner_message": "Leider konnten wir unter der von Ihnen eingegebenen Adresse keinen Partner finden. Bitte versuchen Sie eine andere Adresse.",
    "subscription_missing_title": "Fehlendes Abonnement",
    "subscription_missing_message": "Leider konnte kein aktives Abonnement gefunden werden.",
  },
  "general": {
    "mon": "Mo",
    "tue": "Di",
    "wed": "Mi",
    "thu": "Do",
    "fri": "Fr",
    "sat": "Sa",
    "sun": "So",
    "monday": "Montag",
    "tuesday": "Dienstag",
    "wednesday": "Mittwoch",
    "thursday": "Donnerstag",
    "friday": "Freitag",
    "saturday": "Samstag",
    "sunday": "Sonntag",
    "close": "Schließen",
    "load_more": "Mehr laden"
  },
  "navigation_title": {
    "services": "Dienstleistungen",
    "events": "Events"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Anbieter:in",
      "employees": "Mitarbeiter:in",
      "resource": "Ressource",
      "description": "Beschreibung",
      "email": "E-Mail",
      "website": "Webseite",
      "address": "Adresse",
      "mobile": "Telefon"
    },
    "menu": {
      "book_appointment": "Termin buchen",
      "book_event": "Event buchen",
      "employees": "Mitarbeiter:in",
      "branches": "Filialen",
      "about_us": "Über uns",
      "reviews": "Bewertungen"
    },
    "contact_information": {
      "contact_information": "Kontaktinformationen",
      "opening_hours": "Öffnungszeiten",
      "closed": "Geschlossen"
    },
    "hide_menu_tooltip": "Menü auf Buchungsseite ausblenden",
    "show_menu_tooltip": "Menü auf der Buchungsseite anzeigen",
    "edit": "Bearbeiten",
    "edit_bussiness_hours": "Bedrijfsuren bewerken"
  },
  "view_appointment_component": {
    "appointment_cancelled": "Der Termin wurde erfolgreich abgesagt.",
    "appointment_cancellation_failed": "Leider konnte der Termin nicht abgesagt werden. Bitte kontaktieren Sie den Support.",
    "booked_appointment": "Gebuchter Termin",
    "title": "Titel",
    "date": "Datum",
    "services": "Dienstleistung(en)",
    "resources": "Ressource(n)",
    "price": "Preis",
    "participant": "Teilnehmer",
    "cancel_and_refund": "Stornieren & Rückerstatten",
    "cancel": "Stornieren",
    "cancellation_no_longer_possible": "Stornierung nicht mehr möglich",
    "address": "Durchführungsort",
    "already_paid": "bereits bezahlt"
  },
  "event": {
    "bookable_events": "Unsere buchbaren Veranstaltungen/Gruppentermine",
    "list": "Liste",
    "grid": "Raster",
    "store_filter": "Standort-Filter",
    "category_filter": "Kategorien-Filter",
    "all": "Alle",
    "book": "Buchen",
    "no_place_defined": "Kein Ort definiert",
    "free": "Kostenlos",
    "hidden": "Versteckt",
    "no_events": "Der Anbieter hat noch keine Events erfasst.",
    "hidden_events_message": "Die Ereignisse dieses Partners sind verborgen. Bitte benutzen Sie den direkten Link zu der Veranstaltung, die Sie von dem Partner erhalten haben, um sich anzumelden.",
    "filter_result_null": "Es gibt keine Ergebnisse, die deinen Kriterien entsprechen.",
    "no_regular_count": "Keine freien Plätze",
    "regular_count_singular": "1 freier Platz",
    "regular_count_plural": "{{ number }} freie Plätze",
    "fully_booked": "Ausgebucht"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Es scheint, dass der bereitgestellte Konferenzlink ungültig ist. Bitte überprüfe den Link und versuche es erneut.",
      "expired_link": "Es tut uns leid, aber dieser Konferenzlink ist abgelaufen. Bitte kontaktiere den Organisator für weitere Hilfe.",
      "not_belonging": "Hoppla, es sieht so aus, als wäre diese Konferenz mit einem anderen Partner verknüpft.",
      "booked": "Dieser Buchungslink wurde bereits verwendet, daher wird direkt zur Terminbestätigungsseite weitergeleitet."
    },
    "attendees": "Teilnehmer",
    "more_attendees": "+{{ count }} Mehr",
    "less_attendees": "Weniger Teilnehmer anzeigen",
    "optional": "Optional",
    "required": "Erforderlich"
  },
  "event_details": {
    "place": "Ort der Durchführung",
    "price": "Preis",
    "meeting_type_helper_text": "Calenso erstellt ein Online-Meeting, sobald sich der Kunde registriert",
    "taxes": "(inkl. {{percentage}}% MwSt.)",
    "free": "Kostenlos",
    "hidden": "Versteckt",
    "view_on_map": "Auf der Karte sehen",
    "error_while_loading_map": "Die Karte konnte nicht geladen werden. Versuche es noch einmal!"
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - Alle Rechte vorbehalten."
  },
  "page_not_found_component": {
    "subtitle_1": "Hoppla, es sieht so aus, als hättest du dich verirrt...",
    "subtitle_2": "Nutze die Gelegenheit, dich für einen Moment zu entspannen.",
    "text": "Entspanne dich für die nächsten drei Minuten und mache die Stretching-Übung im Video unten. Nach der kurzen Sitzung ist dein Körper frei von negativen Gefühlen und du wirst dich wieder wie ein Neugeborenes fühlen.",
    "play_video": "Übungsvideo abspielen"
  }
};
