import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@env/environment';
import * as Sentry from "@sentry/angular-ivy";
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { SentryConfig } from './sentry.config';

if (environment.production) {
  enableProdMode();
  Sentry.init(SentryConfig);
}

function bootstrap() {
  bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
