import { Routes } from '@angular/router';
import { PartnerResolveFn } from '@services/resolve/partner.resolve';
import { environment } from '@env/environment';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: environment.defaultBookingPartnerName,
    pathMatch: 'full'
  },
  {
    path: ':bookingName/conference',
    loadChildren: () => import('@routes/conference.routes').then(m => m.conferenceRoutes),
    resolve: {
      partner: PartnerResolveFn
    },
  },
  {
    path: ':bookingName/events',
    loadChildren: () => import('@routes/events.routes').then(m => m.eventRoutes),
    resolve: {
      partner: PartnerResolveFn
    },
  },
  {
    path: ':bookingName',
    loadChildren: () => import('@routes-constants/appointments.routes').then(m => m.appointmentRoutes),
    resolve: {
      partner: PartnerResolveFn
    }
  },
  {
    path: ':bookingName/:workerUsername',
    loadChildren: () => import('@routes-constants/appointments.routes').then(m => m.appointmentRoutes),
    resolve: {
      partner: PartnerResolveFn
    }
  },
  {
    path: ':bookingName/:workerUsername/:serviceUrl',
    loadChildren: () => import('@routes-constants/appointments.routes').then(m => m.appointmentRoutes),
    resolve: {
      partner: PartnerResolveFn
    }
  }
];
