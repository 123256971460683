import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, inject } from '@angular/core';
import { PartnerService } from '@services/feature-service/partner.service';
import { LoggerService } from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private partnerSerice = inject(PartnerService);
  private platformId = inject(PLATFORM_ID);

  constructor(
    @Inject('LOCALSTORAGE') private localStorage: any
  ) { }

  get(key: string): string {
    try {
      if (isPlatformBrowser(this.platformId)) {
        return this.localStorage.getItem(`${key}_${this.partnerSerice.partner?.uuid}`);
      }
    } catch (e) {
      LoggerService.warn('Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.');
    }
  }

  set(key: string, value: string, appendPartnerUuid = true): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        const name = appendPartnerUuid ? `${key}_${this.partnerSerice.partner?.uuid}` : key;
        this.localStorage.setItem(name, value);
      }
    } catch (e) {
      LoggerService.warn('Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.');
    }
  }

  remove(key: string): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        this.localStorage.removeItem(`${key}_${this.partnerSerice.partner?.uuid}`);
      }
    } catch (e) {
      LoggerService.warn('Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.');
    }
  }

  clear(): void {
    try {
      if (isPlatformBrowser(this.platformId)) {
        this.localStorage.clear();
      }
    } catch (e) {
      LoggerService.warn('Failed to read the \'localStorage\' property from \'Window\': Access is denied for this document.');
    }
  }
}
