import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  protected readonly encryptSecretKey = environment.encryptedKey;

  encryptValue(value: string): string {
    const ciphertext = CryptoJS.AES.encrypt(value, this.encryptSecretKey);
    return ciphertext.toString();
  }

  decryptValue(encryptedString: string): string {
    const bytes = CryptoJS.AES.decrypt(encryptedString, this.encryptSecretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
}
