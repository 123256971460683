import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CalioIconComponent } from '@components/calio-button/calio-icon/calio-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-calio-alert',
  templateUrl: './calio-alert.component.html',
  standalone: true,
  imports: [ NgIf, TranslateModule, NgClass, CalioIconComponent ]
})
export class CalioAlertComponent {

  @Input() type: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() customClass: { [key:string]: boolean } = {};
}
