import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, inject } from '@angular/core';
import { SUBSCRIPTIONS, TEMPLATE_CONSTANTS_IDS } from '@constants/app.constants';
import { CssOverwritesDbModel } from '@data-models/css-overwrites.model';
import { FeaturesDbModel } from '@data-models/features-db.model';
import { PartnerDbModel, TokenType } from '@data-models/partner-db.model';
import { PartnerSettingDbModel } from '@data-models/partner-setting-db.model';
import { CustomBookingMessageTemplateDbModel } from '@data-models/templates-db.model';
import { environment } from '@env/environment';
import { DomManipulationService } from '@services/util-service/dom-manipulation.service';
import { HttpClientService } from '@services/util-service/http-client.service';
import { LoggerService } from '@services/util-service/logger.service';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  partnerLoadEvent = new EventEmitter<PartnerDbModel>();
  partnerCssOverwritesEvent = new EventEmitter<CssOverwritesDbModel>();
  readonly templateConstantsIds = TEMPLATE_CONSTANTS_IDS;

  private httpClientService = inject(HttpClientService);
  private domManipulationService = inject(DomManipulationService);
  protected featuresList: { [key: string]: boolean } = {};

  private _accessToken!: string;
  get accessToken(): string {
    return this._accessToken
  };

  private _partner!: PartnerDbModel;
  get partner(): PartnerDbModel {
    return this._partner;
  }

  private _isWhitelabeledPartner = false;
  get isWhitelabeledPartner(): boolean {
    return this._isWhitelabeledPartner;
  }

  private _partnerTemplates: CustomBookingMessageTemplateDbModel[] = [];
  get partnerTemplates(): CustomBookingMessageTemplateDbModel[] {
    return this._partnerTemplates;
  }

  private _partnerBookingName = environment.defaultBookingPartnerName;
  get partnerBookingName(): string {
    return this._partnerBookingName;
  }

  private _isInternal = false;
  get isInternal(): boolean {
    return this._isInternal;
  }

  getPartnersWidgetInformation(
    body: { [key: string]: string | boolean },
    triggerpartnerLoadEvent = true
  ): Observable<PartnerDbModel> {
    this._partnerBookingName = body.bookingName as string;
    body.token_type = TokenType.publicWidget;
    if (body?.internal) {
      delete body.internal;
      body.token_type = TokenType.internalWidget;
    }

    return this.httpClientService.post('partners/information', body, {}).pipe(
      switchMap((partner: PartnerDbModel) => {
        partner.country.is_multi_language = 1;
        this._partner = partner;
        this._partner.has_active_subscription = true;
        this._accessToken = this.partner?.token;
        this._isWhitelabeledPartner = (this.partner.subscription.subscription_type_id >= SUBSCRIPTIONS.SUBSCRIPTION_WHITELABEL_TYPE.id);
        this.loadSubscriptionFeatures(partner.features);
        if (triggerpartnerLoadEvent) {
          this.partnerLoadEvent.emit(this.partner);
        }
        return of(this.partner);
      }),
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        throw error;
      })
    );
  }

  getPartnerTemplates(): Observable<CustomBookingMessageTemplateDbModel[]> {
    return this.httpClientService.post('templates/templates_by_ids', {
      ids: [
        this.templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_PAGETITLE,
        this.templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_APPOINTMENT,
        this.templateConstantsIds.WHITELABEL.WHITELABEL_BOOKINGPAGE_DESCRIPTION_EVENT,
        this.templateConstantsIds.EVENT_WIDGET_LABELS.WIDGET_EVENT_BOOK_LABEL,
        this.templateConstantsIds.EVENT_WIDGET_LABELS.WIDGET_EVENT_NO_MORE_FREE_SLOTS_LABEL
      ]
    }, {}).pipe(
      catchError(() => {
        LoggerService.warn('Error while getting partner template');
        this._partnerTemplates = [];
        return of([]);
      }),
      switchMap((templates: CustomBookingMessageTemplateDbModel[]) => {
        this._partnerTemplates = templates;
        return of(this.partnerTemplates);
      })
    );
  }

  verifyInternalToken(token: string): Observable<{ success: boolean }> {
    return this.httpClientService.post('partners/verify_internal_token', { token }, {}).pipe(
      catchError(() => {
        return of({ success: false });
      }),
      switchMap((result: { success: boolean }) => {
        this._isInternal = result.success;
        return of({ success: result.success });
      })
    );
  }

  loadWidgetAndConfigure(): void {
    this.domManipulationService.appendWidgetWebComponentDependencies();
  }

  getPartnerSettingsByIds(ids: number[]): Observable<PartnerSettingDbModel[]> {
    const body: { ids: number[] } = { ids };
    return this.httpClientService.post('partners_settings/getSettingsByIds', body, {}).pipe(
      catchError((error: HttpErrorResponse) => {
        LoggerService.error(error);
        return of([]);
      }),
      switchMap((result: PartnerSettingDbModel[]) => {
        if (result) {
          return of(result);
        } else {
          return of([]);
        }
      })
    );
  }

  protected loadSubscriptionFeatures(features: FeaturesDbModel[]): void {
    if (features?.length > 0) {
      features.forEach(feature => {
        this.featuresList[feature.feature.name] = true;
      });
    }
  }

  hasFeature(feature: string): boolean {
    return this.featuresList[feature] ? this.featuresList[feature] : false;
  }

  getCssOverwrites(): Observable<CssOverwritesDbModel> {
    return this.httpClientService.get(`css_overwrites/active?type=booking_page`, {}).pipe(
      catchError(() => {
        return of(null);
      })
    );
  }
}
