export const es_ES = {
  "error_messages": {
    "xss_error_message": "Este texto contiene etiquetas HTML no admitidas.",
    "configuration_error_title": "Error de configuración",
    "configuration_error_message": "El servicio de reservas no está habilitado.",
    "invalid_partner_title": "Dirección de socio inválida",
    "invalid_partner_message": "Lamentablemente, no pudimos encontrar un socio en la dirección que ingresaste. Por favor, intenta con otra dirección.",
    "subscription_missing_title": "Suscripción faltante",
    "subscription_missing_message": "Lamentablemente, no se encontró ninguna suscripción activa."
  },
  "general": {
    "mon": "Lun",
    "tue": "Mar",
    "wed": "Mié",
    "thu": "Jue",
    "fri": "Vie",
    "sat": "Sáb",
    "sun": "Dom",
    "monday": "Lunes",
    "tuesday": "Martes",
    "wednesday": "Miércoles",
    "thursday": "Jueves",
    "friday": "Viernes",
    "saturday": "Sábado",
    "sunday": "Domingo",
    "close": "Cerrar",
    "load_more": "Cargar más"
  },
  "navigation_title": {
    "services": "Servicios",
    "events": "Eventos"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Proveedor",
      "employees": "Empleados",
      "resource": "Recursos",
      "description": "Descripción",
      "email": "Correo electrónico",
      "website": "Sitio web",
      "address": "Dirección",
      "mobile": "Teléfono"
    },
    "menu": {
      "book_appointment": "Reservar cita",
      "book_event": "Reservar evento",
      "employees": "Empleados",
      "branches": "Sucursales",
      "about_us": "Sobre nosotros",
      "reviews": "Opiniones"
    },
    "contact_information": {
      "contact_information": "Información de contacto",
      "opening_hours": "Horario de apertura",
      "closed": "Cerrado"
    },
    "hide_menu_tooltip": "Ocultar menú en la página de reserva",
    "show_menu_tooltip": "Mostrar menú en la página de reserva",
    "edit": "Editar",
    "edit_bussiness_hours": "Editar horario comercial"
  },
  "event": {
    "bookable_events": "Nuestros eventos/citas grupales reservables",
    "list": "Lista",
    "grid": "Cuadrícula",
    "store_filter": "Filtro de tienda",
    "category_filter": "Filtro de categoría",
    "all": "Todo",
    "book": "Reservar",
    "no_place_defined": "No se ha definido un lugar",
    "free": "Gratis",
    "hidden": "Oculto",
    "no_events": "Este proveedor no ha especificado eventos aún.",
    "hidden_events_message": "Los eventos de este socio están ocultos. Por favor, utiliza el enlace directo al evento que recibiste del socio para registrarte.",
    "filter_result_null": "No hay resultados que coincidan con tus criterios.",
    "no_regular_count": "No hay plazas libres",
    "regular_count_singular": "1 asiento libre",
    "regular_count_plural": "{{ number }} plazas libres",
    "fully_booked": "Completamente reservado"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Parece que el enlace de la conferencia proporcionado no es válido. Por favor, verifica el enlace e intenta nuevamente.",
      "expired_link": "Lo sentimos, pero este enlace de conferencia ha caducado. Por favor, contacta al organizador para obtener más ayuda.",
      "not_belonging": "¡Ups! Parece que esta conferencia está vinculada a otro socio.",
      "booked": "Este enlace de reserva ya ha sido utilizado, por lo que serás redirigido directamente a la página de confirmación de la cita."
    },
    "attendees": "Asistentes",
    "more_attendees": "+{{ count }} Más",
    "less_attendees": "Ver menos asistentes",
    "optional": "Opcional",
    "required": "Obligatorio"
  },
  "event_details": {
    "place": "Lugar de ejecución",
    "price": "Precio",
    "meeting_type_helper_text": "Calenso genera una reunión en línea tan pronto como el cliente se registra",
    "taxes": "(incl. {{percentage}}% IVA)",
    "free": "Gratis",
    "hidden": "Oculto",
    "view_on_map": "Ver en el mapa",
    "error_while_loading_map": "No se pudo cargar el mapa. ¡Inténtalo de nuevo!"
  },
  "footer": {
    "copyright_text": " - Derechos de autor {{years}} - Todos los derechos reservados."
  },
  "page_not_found_component": {
    "subtitle_1": "Ups, parece que te has perdido...",
    "subtitle_2": "Aprovecha esta oportunidad para relajarte por un momento.",
    "text": "Relájate durante los próximos tres minutos y realiza el ejercicio de estiramiento en el video a continuación. Después de la breve sesión, tu cuerpo estará libre de sentimientos negativos y te sentirás como un recién nacido nuevamente.",
    "play_video": "Reproducir video de ejercicio"
  }
};
