export const it_CH = {
  "error_messages": {
    "xss_error_message": "Questo testo contiene tag HTML non supportati.",
    "configuration_error_title": "Errore di configurazione",
    "configuration_error_message": "Il servizio di prenotazione non è abilitato.",
    "invalid_partner_title": "Indirizzo partner non valido",
    "invalid_partner_message": "Purtroppo non siamo riusciti a trovare un partner all'indirizzo inserito. Si prega di provare con un altro indirizzo.",
    "subscription_missing_title": "Abbonamento mancante",
    "subscription_missing_message": "Purtroppo non è stato possibile trovare alcun abbonamento attivo."
  },
  "general": {
    "mon": "Lun",
    "tue": "Mar",
    "wed": "Mer",
    "thu": "Gio",
    "fri": "Ven",
    "sat": "Sab",
    "sun": "Dom",
    "monday": "Lunedì",
    "tuesday": "Martedì",
    "wednesday": "Mercoledì",
    "thursday": "Giovedì",
    "friday": "Venerdì",
    "saturday": "Sabato",
    "sunday": "Domenica",
    "close": "Chiudi",
    "load_more": "Carica di più"
  },
  "navigation_title": {
    "about": "A proposito di",
    "services": "Servizi",
    "events": "Eventi",
    "rating": "Valutazione"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Fornitore",
      "employees": "Dipendenti",
      "resource": "Risorsa",
      "description": "Descrizione",
      "email": "Email",
      "website": "Sito web",
      "address": "Indirizzo",
      "mobile": "Telefono"
    },
    "menu": {
      "book_appointment": "Prenota un appuntamento",
      "book_event": "Prenotare un evento",
      "employees": "Dipendenti",
      "branches": "Filiali",
      "about_us": "Chi siamo",
      "reviews": "Recensioni"
    },
    "contact_information": {
      "contact_information": "Informazioni di contatto",
      "opening_hours": "Orari di apertura",
      "closed": "Chiuso"
    },
    "hide_menu_tooltip": "Nascondi il menu nella pagina di prenotazione",
    "show_menu_tooltip": "Mostra il menu nella pagina di prenotazione",
    "edit": "Modifica",
    "edit_bussiness_hours": "Modifica degli orari di lavoro"
  },
  "event": {
    "bookable_events": "I nostri eventi prenotabili/appuntamenti di gruppo",
    "list": "Liste",
    "grid": "Griglia",
    "store_filter": "Filtro del negozio",
    "category_filter": "Filtro della categoria",
    "all": "Tutti",
    "book": "Libro",
    "no_place_defined": "Nessun luogo definito",
    "free": "Gratuito",
    "hidden": "Gratuito",
    "no_events": "L'offerente non ha inserito ancora nessun evento.",
    "hidden_events_message": "Gli eventi di questo partner sono nascosti. Si prega di utilizzare il link diretto all'evento ricevuto dal partner per registrarsi.",
    "filter_result_null": "Non ci sono risultati che corrispondono ai tuoi criteri.",
    "no_regular_count": "Nessun posto libero",
    "regular_count_singular": "1 spazio libero",
    "regular_count_plural": "{{ number }} posti liberi",
    "fully_booked": "Pieno"
  },
  "event_details": {
    "place": "Luogo di esecuzione",
    "price": "Prezzo",
    "meeting_type_helper_text": "Calenso genera un incontro online non appena il cliente si registra",
    "taxes": "(incl. {{percentage}}% di IVA)",
    "free": "Gratuito",
    "hidden": "Nascosto",
    "view_on_map": "Visualizza sulla mappa",
    "error_while_loading_map": "Impossibile caricare la mappa. Riprova!"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Sembra che il link della conferenza fornito non sia valido. Per favore, controlla il link e riprova.",
      "expired_link": "Ci dispiace, ma questo link alla conferenza è scaduto. Per favore, contatta l'organizzatore per ulteriori assistenza.",
      "not_belonging": "Ops, sembra che questa conferenza sia collegata a un altro partner.",
      "booked": "Questo link di prenotazione è già stato utilizzato, quindi verrai reindirizzato direttamente alla pagina di conferma dell'appuntamento."
    },
    "attendees": "Partecipanti",
    "more_attendees": "+{{ count }} in Piu",
    "less_attendees": "Mostra meno partecipanti",
    "optional": "Opzionale",
    "required": "Richiesto"
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - Tutti i diritti riservati."
  },
  "page_not_found_component": {
    "subtitle_1": "Oops, sembra che ti sei perso...",
    "subtitle_2": "Prendi questo cambiamento per rilassarti un momento.",
    "text": "Rilassatevi per i prossimi tre minuti e fate l'esercizio di streching nel video qui sotto. Dopo la breve seduta il vostro corpo sarà libero da sensazioni negative e vi sentirete di nuovo come un neonato.",
    "play_video": "Riproduci il video dell'esercizio"
  }
};
