export const sv_SE = {
  "error_messages": {
    "xss_error_message": "Denna text innehåller otillåtna HTML-taggar.",
    "configuration_error_title": "Konfigurationsfel",
    "configuration_error_message": "Bokningstjänsten är inte aktiverad.",
    "invalid_partner_title": "Ogiltig partneradress",
    "invalid_partner_message": "Tyvärr kunde vi inte hitta en partner på den adress du angav. Var vänlig försök med en annan adress.",
    "subscription_missing_title": "Prenumeration saknas",
    "subscription_missing_message": "Tyvärr kunde ingen aktiv prenumeration hittas."
  },
  "general": {
    "mon": "Mån",
    "tue": "Tis",
    "wed": "Ons",
    "thu": "Tor",
    "fri": "Fre",
    "sat": "Lör",
    "sun": "Sön",
    "monday": "Måndag",
    "tuesday": "Tisdag",
    "wednesday": "Onsdag",
    "thursday": "Torsdag",
    "friday": "Fredag",
    "saturday": "Lördag",
    "sunday": "Söndag",
    "close": "Stäng",
    "load_more": "Ladda mer"
  },
  "navigation_title": {
    "services": "Tjänster",
    "events": "Evenemang"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Leverantör",
      "employees": "Anställda",
      "resource": "Resurs",
      "description": "Beskrivning",
      "email": "E-post",
      "website": "Webbplats",
      "address": "Adress",
      "mobile": "Telefon"
    },
    "menu": {
      "book_appointment": "Boka tid",
      "book_event": "Boka evenemang",
      "employees": "Anställda",
      "branches": "Filialer",
      "about_us": "Om oss",
      "reviews": "Recensioner"
    },
    "contact_information": {
      "contact_information": "Kontaktinformation",
      "opening_hours": "Öppettider",
      "closed": "Stängt"
    },
    "hide_menu_tooltip": "Dölj menyn på bokningssidan",
    "show_menu_tooltip": "Visa menyn på bokningssidan",
    "edit": "Redigera",
    "edit_bussiness_hours": "Redigera öppettider"
  },
  "event": {
    "bookable_events": "Våra bokningsbara evenemang/gruppavtal",
    "list": "Lista",
    "grid": "Rutnät",
    "store_filter": "Butiksfiltrering",
    "category_filter": "Kategorifiltrering",
    "all": "Alla",
    "book": "Boka",
    "no_place_defined": "Inget ställe definierat",
    "free": "Gratis",
    "hidden": "Dold",
    "no_events": "Denna leverantör har inte angivit några evenemang ännu.",
    "hidden_events_message": "Evenemangen från denna partner är dolda. Var vänlig använd den direkta länken till evenemanget som du fick från partnern för att registrera dig.",
    "filter_result_null": "Det finns inga resultat som matchar dina kriterier.",
    "no_regular_count": "Inga lediga platser",
    "regular_count_singular": "1 ledig plats",
    "regular_count_plural": "{{ number }} lediga platser",
    "fully_booked": "Fullbokat"
  },
  "event_details": {
    "place": "Utförandeplats",
    "price": "Pris",
    "meeting_type_helper_text": "Calenso skapar ett online-möte så snart kunden registrerar sig",
    "taxes": "(inkl. {{percentage}}% moms)",
    "free": "Gratis",
    "hidden": "Dold",
    "view_on_map": "Visa på karta",
    "error_while_loading_map": "Kunde inte ladda kartan. Försök igen!"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Det verkar som att den angivna konferenslänken är ogiltig. Kontrollera länken och försök igen.",
      "expired_link": "Vi är ledsna, men den här konferenslänken har gått ut. Kontakta arrangören för ytterligare hjälp.",
      "not_belonging": "Hoppsan, det verkar som att den här konferensen är kopplad till en annan partner.",
      "booked": "Denna bokningslänk har redan använts, så du kommer att omdirigeras direkt till bokningsbekräftelsesidan."
    },
    "attendees": "Deltagare",
    "more_attendees": "+{{ count }} Till",
    "less_attendees": "Visa färre deltagare",
    "optional": "Valfritt",
    "required": "Obligatoriskt"
  },
  "footer": {
    "copyright_text": " - Upphovsrätt {{years}} - Alla rättigheter förbehållna."
  },
  "page_not_found_component": {
    "subtitle_1": "Hoppsan, det verkar som att du har gått vilse...",
    "subtitle_2": "Ta tillfället i akt att koppla av en stund.",
    "text": "Koppla av de närmaste tre minuterna och gör stretchingövningen i videon nedan. Efter den korta sessionen kommer din kropp vara fri från negativa känslor och du kommer känna dig som en nyfödd igen.",
    "play_video": "Spela övningsvideo"
  }
};
