export const en_US = {
  "error_messages": {
    "xss_error_message": "This text contains unsupported HTML tags.",
    "configuration_error_title": "Configuration Error",
    "configuration_error_message": "Booking service is not enabled.",
    "invalid_partner_title": "Invalid partner address",
    "invalid_partner_message": "Unfortunately, we could not find a partner at the address you entered. Please try another address.",
    "subscription_missing_title": "Missing subscription",
    "subscription_missing_message": "Unfortunately, no active subscription could be found."
  },
  "general": {
    "mon": "Mon",
    "tue": "Tue",
    "wed": "Wed",
    "thu": "Thu",
    "fri": "Fri",
    "sat": "Sat",
    "sun": "Sun",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday",
    "close": "Close",
    "load_more": "Load more"
  },
  "navigation_title": {
    "services": "Services",
    "events": "Events"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Provider",
      "employees": "Employees",
      "resource": "Resource",
      "description": "Description",
      "email": "Email",
      "website": "Website",
      "address": "Address",
      "mobile": "Phone"
    },
    "menu": {
      "book_appointment": "Book appointment",
      "book_event": "Book event",
      "employees": "Employees",
      "branches": "Branches",
      "about_us": "About us",
      "reviews": "Reviews"
    },
    "contact_information": {
      "contact_information": "Contact information",
      "opening_hours": "Opening hours",
      "closed": "Closed"
    },
    "hide_menu_tooltip": "Hide menu in booking page",
    "show_menu_tooltip": "Show menu in booking page",
    "edit": "Edit",
    "edit_bussiness_hours": "Edit business hours"
  },
  "event": {
    "bookable_events": "Our bookable events/group appointments",
    "list": "List",
    "grid": "Grid",
    "store_filter": "Store filter",
    "category_filter": "Category filter",
    "all": "All",
    "book": "Book",
    "no_place_defined": "No place defined",
    "free": "Free",
    "hidden": "Hidden",
    "no_events": "This provider did not specify any events yet.",
    "hidden_events_message": "The events of this partner are hidden. Please use the direct link to the event you received from the partner in order to register.",
    "filter_result_null": "There are no results that match your criteria.",
    "no_regular_count": "No free seat",
    "regular_count_singular": "1 free seat",
    "regular_count_plural": "{{ number }} free seats",
    "fully_booked": "Fully booked"
  },
  "event_details": {
    "place": "Place of execution",
    "price": "Price",
    "meeting_type_helper_text": "Calenso generates an online meeting as soon as the customer registers",
    "taxes": "(incl. {{percentage}}% VAT)",
    "free": "Free",
    "hidden": "Hidden",
    "view_on_map": "View on Map",
    "error_while_loading_map": "Could not load map. Try again!"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "It seems like the conference link provided is invalid. Please double-check the link and try again.",
      "expired_link": "We're sorry, but this conference link has expired. Please contact the organizer for further assistance.",
      "not_belonging": "Oops, it looks like this conference is linked to a different partner.",
      "booked": "This booking link has already been used, so you will be redirected to the appointment confirmation page."
    },
    "attendees": "Attendees",
    "more_attendees": "+{{ count }} More",
    "less_attendees": "View less attendees",
    "optional": "Optional",
    "required": "Required",
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - All rights reserved."
  },
  "page_not_found_component": {
    "subtitle_1": "Oops, it looks like you're lost...",
    "subtitle_2": "Take this chance to relax for a moment.",
    "text": "Relax for the next three minutes and do the streching excercise in the video below. After the short session your body will be free of negative feelings and you will feel like a newborn again.",
    "play_video": "Play exercise video"
  }
};
