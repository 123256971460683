import { Component, Input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { PartnerDbModel } from '@data-models/partner-db.model';

@Component({
  standalone: true,
  imports: [
    MatCardModule,
  ],
  selector: 'app-calio-card',
  templateUrl: './calio-card.component.html'
})
export class CalioCardComponent {

  @Input() customCss = '';
  @Input() partner: PartnerDbModel;
}
