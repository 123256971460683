import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from '@services/util-service/logger.service';

@Pipe({
  name: 'translation',
  standalone: true
})
export class CalioTranslationPipe implements PipeTransform {

  transform(value: any, targetLabel: string, language: string, args?: any): any {
    try {
      if (value?.is_multi_language === 0) {
        if (value[targetLabel]) {
          return value[targetLabel].trim();
        } else if (
          !value[targetLabel]
          && value?._translations
          && value?._translations[language]
          && value?._translations[language][targetLabel]
        ) {
          return value?._translations[language][targetLabel].trim();
        }
      } else if (
        value?.template
        && value?.template !== null
        && value?.is_multi_language === 1
        && value?.template.length !== 0
      ) {
        if (value?.template._translations[language]?.template_content) {
          return value?.template._translations[language].template_content.trim();
        }
        return value?.template?._translations[language]?.template_content;
      } else if (
        value?._translations
        && value?._translations !== 0
        && value?.is_multi_language === 1
        && value?._translations[language]
        && value?._translations[language][targetLabel]
      ) {
        return value?._translations[language][targetLabel].trim();
      } else {
        if (value[targetLabel]) {
          return value[targetLabel].trim();
        }
        return value[targetLabel];
      }
    } catch (error) {
      LoggerService.error(error);
    }
  }
}
