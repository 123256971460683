export const fr_CH = {
  "error_messages": {
    "xss_error_message": "Ce texte contient des balises HTML non prises en charge.",
    "configuration_error_title": "Erreur de configuration",
    "configuration_error_message": "Le service de réservation n'est pas activé.",
    "invalid_partner_title": "Adresse du partenaire non valide",
    "invalid_partner_message": "Malheureusement, nous n'avons pas pu trouver de partenaire à l'adresse que vous avez saisie. Veuillez essayer une autre adresse.",
    "subscription_missing_title": "Abonnement manquant",
    "subscription_missing_message": "Malheureusement, aucun abonnement actif n'a été trouvé."
  },
  "general": {
    "mon": "Lun",
    "tue": "Mar",
    "wed": "Mer",
    "thu": "Jeu",
    "fri": "Ven",
    "sat": "Sam",
    "sun": "Dim",
    "monday": "Lundi",
    "tuesday": "Mardi",
    "wednesday": "Mercredi",
    "thursday": "Jeudi",
    "friday": "Vendredi",
    "saturday": "Samedi",
    "sunday": "Dimanche",
    "close": "Fermer",
    "load_more": "Charger plus"
  },
  "navigation_title": {
    "services": "Prestations de services",
    "events": "Événements"
  },
  "booking_page": {
    "partner_header": {
      "provider": "Prestataire",
      "employees": "Employés",
      "resource": "Ressource",
      "description": "Description",
      "email": "Courriel",
      "website": "Site web",
      "address": "Adresse",
      "mobile": "Téléphone"
    },
    "menu": {
      "book_appointment": "Réserver un rendez-vous",
      "book_event": "Réserver un événement",
      "employees": "Employés",
      "branches": "Branches",
      "about_us": "A propos de nous",
      "reviews": "Critiques"
    },
    "contact_information": {
      "contact_information": "Informations de contact",
      "opening_hours": "Heures d'ouverture",
      "closed": "Fermé"
    },
    "hide_menu_tooltip": "Cacher le menu dans la page de réservation",
    "show_menu_tooltip": "Afficher le menu sur la page de réservation",
    "edit": "Modifier",
    "edit_bussiness_hours": "Modifier les heures d'ouverture"
  },
  "event": {
    "bookable_events": "Nos événements réservables/rendez-vous de groupe",
    "list": "Liste",
    "grid": "Grille",
    "store_filter": "Filtre de magasin",
    "category_filter": "Filtre de catégorie",
    "all": "Tous les",
    "book": "Livre",
    "no_place_defined": "Pas de lieu défini",
    "free": "Gratuit",
    "hidden": "Caché",
    "no_events": "Le fournisseur n'a pas encore enregistré d'événements.",
    "hidden_events_message": "Les événements de ce partenaire sont cachés. Veuillez utiliser le lien direct vers l'événement que vous avez reçu du partenaire afin de vous inscrire.",
    "filter_result_null": "Aucun résultat ne correspond à tes critères.",
    "no_regular_count": "Pas de places libres",
    "regular_count_singular": "1 espace libre",
    "regular_count_plural": "{{ number }} places libres",
    "fully_booked": "Complet"
  },
  "event_details": {
    "place": "Lieu d'exécution",
    "price": "Prix",
    "meeting_type_helper_text": "Calenso génère une réunion en ligne dès l'inscription du client.",
    "taxes": "(incl. {{percentage}}% de TVA)",
    "free": "Gratuit",
    "hidden": "Caché",
    "view_on_map": "Voir sur la carte",
    "error_while_loading_map": "Impossible de charger la carte. Essaie à nouveau !"
  },
  "conference": {
    "error_messages": {
      "invalid_link": "Il semble que le lien de la conférence fourni ne soit pas valide. Veuillez vérifier le lien et réessayer.",
      "expired_link": "Nous sommes désolés, mais ce lien de conférence a expiré. Veuillez contacter l'organisateur pour obtenir de l'aide supplémentaire.",
      "not_belonging": "Oups, il semble que cette conférence soit liée à un autre partenaire.",
      "booked": "Ce lien de réservation a déjà été utilisé, vous serez donc redirigé directement vers la page de confirmation du rendez-vous."
    },
    "attendees": "Participants",
    "more_attendees": "+{{ count }} de Plus",
    "less_attendees": "Afficher moins de participants",
    "optional": "Optionnel",
    "required": "Obligatoire"
  },
  "footer": {
    "copyright_text": " - Copyright {{years}} - Tous droits réservés."
  },
  "page_not_found_component": {
    "subtitle_1": "Oups, on dirait que vous êtes perdu...",
    "subtitle_2": "Prenez ce changement pour vous détendre un moment.",
    "text": "Détendez-vous pendant les trois prochaines minutes et faites l'exercice d'étirement dans la vidéo ci-dessous. Après la courte séance, votre corps sera libéré des sentiments négatifs et vous vous sentirez comme un nouveau-né.",
    "play_video": "Jouer la vidéo de l'exercice"
  }
};
