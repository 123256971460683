import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpClientService {

  readonly restAPI = environment.apiUrl;

  constructor(
    private httpClient: HttpClient
  ) { }

  get(path: string, options: any): Observable<any> {
    return this.httpClient.get<any>(this.restAPI + path, options);
  }

  post(path: string, body: any, options: any): Observable<any> {
    return this.httpClient.post<any>(this.restAPI + path, body, options);
  }

  put(path: string, body: any, options: any): Observable<any> {
    return this.httpClient.put<any>(this.restAPI + path, body, options);
  }

  delete(path: string, options: any): Observable<any> {
    return this.httpClient.delete<any>(this.restAPI + path, options);
  }

}
