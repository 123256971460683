import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { APP_CONSTANTS } from '@constants/app.constants';
import { CustomBookingMessageTemplateDbModel } from '@data-models/templates-db.model';
import { environment } from '@env/environment';
import { CalioTranslationPipe } from '@pipes/calio-translation.pipe';
import { LoggerService } from '@services/util-service/logger.service';

@Injectable({
  providedIn: 'root'
})
export class DomManipulationService {

  isBrowser = false;
  renderer: Renderer2;
  readonly #webcomponentWidgetUrl = environment.webcomponentWidgetUrl;
  readonly #defaultLanguage = APP_CONSTANTS.DEFAULT_LANG;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private calioTranslationPipe: CalioTranslationPipe,
    private meta: Meta,
    private title: Title,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.isBrowser = isPlatformBrowser(this.platformId);

  }

  addClassToBodyTag(className: string) {
    this.renderer.addClass(this.document.body, className);
  }

  removeClassFromBodyTag(className: string) {
    this.renderer.removeClass(this.document.body, className);
  }

  setStyleToBodyTag(styleKey: string, styleValue: string) {
    this.renderer.setStyle(this.document.body, styleKey, styleValue);
  }

  updateCssInParticularClass(className: string, styleKey: string, styleValue: string) {
    const element = this.document.querySelector(className);
    if (element) {
      this.renderer.setStyle(element, styleKey, styleValue);
    }
  }

  updateCssVariable(variableName: string, value: string): void {
    if (this.document?.documentElement) {
      this.document.documentElement.style.setProperty(variableName, value);
    }
  }

  appendWidgetWebComponentDependencies(): void {
    try {
      // Set webcomponent hosted site domain as preconncet
      const preconnectLink = this.document.createElement('link');
      preconnectLink.rel = 'preconnect';
      preconnectLink.href = this.#webcomponentWidgetUrl;
      this.document.head.appendChild(preconnectLink);

      // Load html-imports.js
      const htmlImportsLink = this.document.createElement('script');
      htmlImportsLink.type = 'text/javascript';
      htmlImportsLink.src = `${this.#webcomponentWidgetUrl}/html-imports.js`;
      htmlImportsLink.setAttribute('fetchpriority', 'high');
      this.document.head.appendChild(htmlImportsLink);

      // Link booking page
      const bookingLink = this.document.createElement('link');
      bookingLink.rel = 'import';
      bookingLink.href = `${this.#webcomponentWidgetUrl}/booking.html`;
      bookingLink.setAttribute('fetchpriority', 'high');
      this.document.head.appendChild(bookingLink);
    } catch (error) {
      LoggerService.warn('Error appending link');
      LoggerService.error(error);
    }
  }

  setAuthorMeta(author: string, siteName: string): void {
    LoggerService.log('[Meta] Setting author', author, 'and og:site_name', siteName);
    this.meta.addTag({
      name: 'author',
      property: 'author',
      content: author
    });
    this.meta.addTag({
      name: 'og:site_name',
      property: 'og:site_name',
      content: siteName
    });
    this.meta.addTag({
      name: 'apple-mobile-web-app-title',
      property: 'apple-mobile-web-app-title',
      content: siteName
    });
    this.meta.addTag({
      name: 'application-name',
      property: 'application-name',
      content: siteName
    });
  }

  setDocumentTitle(template: CustomBookingMessageTemplateDbModel): void {
    const title = this.calioTranslationPipe.transform(template, 'template_content', this.#defaultLanguage);
    LoggerService.log('[Meta] Setting og:title', title);
    this.title.setTitle(title);
    this.meta.addTag({
      name: 'og:title',
      property: 'og:title',
      content: title
    });
  }

  setDocumentDescription(template: CustomBookingMessageTemplateDbModel): void {
    const description = this.calioTranslationPipe.transform(template, 'template_content', this.#defaultLanguage);
    LoggerService.log('[Meta] Setting og:description', description);
    this.meta.addTag({
      name: 'description',
      property: 'description',
      content: description
    });
    this.meta.addTag({
      name: 'og:description',
      property: 'og:description',
      content: description
    });
  }

  setDocumentFavicon(favicon: string): void {
    LoggerService.log('[Meta] Setting favicon', favicon);
    const faviconUrl = `${favicon}`;
    const favIcon = this.document.querySelector('#favicon') as HTMLLinkElement;
    const favIconShortcutIcon = this.document.querySelector('#favicon_shortcut_icon') as HTMLLinkElement;
    const favIconTouchIcon = this.document.querySelector('#apple_touch_icon') as HTMLLinkElement;
    const imageSrcIcon = this.document.querySelector('#image_src') as HTMLLinkElement;

    favIcon.href = faviconUrl;
    favIcon.setAttribute('fetchpriority', 'high');

    favIconShortcutIcon.href = faviconUrl;
    favIconShortcutIcon.setAttribute('fetchpriority', 'high');

    favIconTouchIcon.href = faviconUrl;
    favIconTouchIcon.setAttribute('fetchpriority', 'high');

    imageSrcIcon.href = faviconUrl;
    imageSrcIcon.setAttribute('fetchpriority', 'high');
  }

  setOgImageMeta(image: string, altText: string): void {
    LoggerService.log('[Meta] Setting og:image', image);
    this.meta.addTag({
      name: 'og:image',
      property: 'og:image',
      content: image
    });
    this.meta.addTag({
      name: 'og:image:alt',
      property: 'og:image:alt',
      content: altText
    });
  }

  setOgURL(url: string): void {
    LoggerService.log('[Meta] Setting og:url and canonical', url);
    const bookingLink = this.document.createElement('link');
    bookingLink.rel = 'canonical';
    bookingLink.href = url;
    this.document.head.appendChild(bookingLink);

    this.meta.addTag({
      name: 'og:url',
      property: 'og:url',
      content: url
    });
  }

  setThemeColor(hex: string): void {
    hex = hex || '#3a4a66';
    this.meta.addTag({
      name: 'theme-color',
      property: 'theme-color',
      content: hex
    });
  }

  applyCssOverwrite(code: string): void {
    const style = this.document.createElement('style');
    style.setAttribute('scoped', '');
    style.innerHTML = code;
    document.body.append(style);
  }
}
