import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-calio-icon',
  templateUrl: './calio-icon.component.html',
  standalone: true,
  imports: [MatIconModule, NgIf, NgClass],
  host: { ngSkipHydration: 'true' }
})
export class CalioIconComponent {

  @Input() iconType = '';
  @Input() fontIcon = undefined;
  @Input() customClass: { [key: string]: boolean } = {};
  @Input() title = '';

  @Output() onClick = new EventEmitter<void>();

  click(): void {
    this.onClick.emit();
  }
}
